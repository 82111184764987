import { useState } from 'react'
import emailjs from 'emailjs-com'

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'service_3ecw3st', 'template_xwp3bhu', e.target, 'NqLTpLJS5sP9t0F5m'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Skontaktuj się z nami!</h2>
                <p>
                  Uzupełnij poniższy formularz aby wysłać nam maila,
                  a my odpowiemy Ci najszybciej jak to możliwe.
                </p>
              </div>
              <form name='sentMessage' onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Imię i nazwisko'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'/>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'/>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Wiadomość'
                    required
                    onChange={handleChange}
                    />
                  <p className='help-block text-danger'/>
                </div>
                <div id='success'/>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Wyślij wiadomość
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Dane kontaktowe:</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'/> Adres
                </span>
                {props.data ? <a href={'https://goo.gl/maps/enHbmi56nuh8itP3A'}>{props.data.address}</a> : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'/> Telefon
                </span>{' '}
                  {props.data ? <a href={'tel:' + props.data.phone}>{props.data.phone}</a> : 'loading'}<br/>
                  {props.data ? <a href={'tel:' + props.data.phone2}>{props.data.phone2}</a> : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'/> Email
                </span>{' '}
                {props.data ? <a href={'mailto:' + props.data.email}>{props.data.email}</a> : 'loading'}<br/>
                {props.data ? <a href={'mailto:' + props.data.email}>{props.data.email2}</a> : 'loading'}
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'/>
                    </a>
                  </li>

                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'/>
                    </a>
                  </li>
                  <li>
                    <a href={ '/REGULAMIN-STRZELNICY.pdf'} rel={"noreferrer"} target={"_blank"} className={"regulamin"}>
                      REGULAMIN STRZELNICY
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; <a href={"https://strzelnicahistoryczna.pl"}>Strzelnica Historyczna </a> {new Date().getFullYear()} - Wszelkie prawa zastrzeżone
            | Made by <a href={"https://bookit.one"} target={"_blank"} rel={"noreferrer"}>BookitOne</a>
          </p>
        </div>
      </div>
    </div>
  )
}
